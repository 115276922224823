import React, { useRef, useEffect, useState } from 'react';
import { map } from 'underscore';
import { useSelector } from 'react-redux';
import './productsGallery.scss';

import { Text } from '@ui-kit';
import { NoResult } from '@features';
import IllustrationCard from './illustrationCard';
import { getCurrenteSearch } from '@entities';

const PRODUCT_WITH = {min: 185};
const PRODUCT_HEIGHT = {min: 125, max: 175};
const DEFAULT_PRODUCT_HEIGHT = 250;
const MARGIN_DEFAULT = 8;
//const { REACT_APP_PRODUCTS_URL } = process.env;

const ProductsGallery = ({ onSelectTag, products, preloader }) => {
  const galleryContainer = useRef(null);
  const galleryListRef = useRef(null);
  const searchCurrentValue = useSelector(getCurrenteSearch);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [isSorting, setIsSorting] = useState(null);

  const MAX_GALLERY_WIDTH =
    galleryContainer?.current?.getBoundingClientRect().width || 0;
  const MIN_PRODUCT_HEIGHT = MAX_GALLERY_WIDTH > 1225 ? 
    PRODUCT_HEIGHT.max : PRODUCT_HEIGHT.min;

  const galleryClasses = ['gallery__list'];

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  /*useEffect(() => {
    const loadImages = async () => {
      let imagesLoaded = 0;
      products.forEach((image) => {
        const img = new Image();
        img.src = `${REACT_APP_PRODUCTS_URL}/${image.pathToBrowse}`;
        img.onload = () => {
          imagesLoaded++;
          if (imagesLoaded === products.length) {
            sortProducts();
          };
        };
      });
    };

    loadImages();
    // eslint-disable-next-line
  }, [products]);*/

  useEffect(() => {
    if(windowWidth) {
      renderProducts();
    };
    // eslint-disable-next-line
  }, [windowWidth, isShowInfo]);

  useEffect(() => {
    if(galleryListRef.current && MAX_GALLERY_WIDTH) {
      sortProducts();
    }
    // eslint-disable-next-line
  }, [products, MAX_GALLERY_WIDTH]);

  const sortProducts = () => {
    setIsSorting(true)
    const galleryItems = Array.from(document.querySelectorAll('.product__item'));
    let dataRow = 1;
    let dataColumn = 1;
    let restMinWidth = MAX_GALLERY_WIDTH;
    const lineProdArr = [];

    galleryItems.map((product, index) => {
      const nextIndex = index + 1;
      const isLastItem = nextIndex === galleryItems.length;

      product.setAttribute('data-row', dataRow);
      product.setAttribute('data-column', dataColumn);

      const currentWidth = product.getAttribute('data-width');
      const currentHeight = DEFAULT_PRODUCT_HEIGHT;
      const currentMinWidth = Math.max(PRODUCT_WITH.min, currentWidth / currentHeight * MIN_PRODUCT_HEIGHT);
      lineProdArr.push({index: index, width: currentWidth, minWidth: currentMinWidth});

      const nextWidth = galleryItems[nextIndex]?.getAttribute('data-width') || 0;
      const nextHeight = galleryItems[nextIndex]?.offsetHeight ? DEFAULT_PRODUCT_HEIGHT : 0;
      const nextMinWidth = nextWidth / nextHeight * MIN_PRODUCT_HEIGHT || 0;

      const restMinProdWidth = restMinWidth - currentMinWidth;      
      const sumLineProdWidth = MAX_GALLERY_WIDTH - restMinProdWidth
      const marginSum = MARGIN_DEFAULT * lineProdArr.length - MARGIN_DEFAULT;
      const allMinWidth = sumLineProdWidth + marginSum;       
      const checkNextWidth = allMinWidth + nextMinWidth + MARGIN_DEFAULT;

      if(checkNextWidth > MAX_GALLERY_WIDTH || isLastItem) {
        let sumWidth = 0;
        lineProdArr.map(item => sumWidth = sumWidth + +item.width)
        const percentScale = (MAX_GALLERY_WIDTH - marginSum) / sumWidth;

        lineProdArr.map((item, i) => {
          const isLineLastItem = (i + 1) === lineProdArr.length
          const image = galleryItems[item.index];

          const number = (item.width * percentScale) * 2;
          const newImgWidth = Math.trunc(number) / 2;

          if(isLastItem && isLineLastItem && lineProdArr.length === 1) {
            image.style.cssText = ``;
            return item;
          }

          image.style.cssText = `width: ${newImgWidth}px; flex-grow:${newImgWidth}`;
          return item;
        });
      
        ++dataRow;
        dataColumn = 1;
        restMinWidth = MAX_GALLERY_WIDTH;
        lineProdArr.length = 0;

        return product;
      }

      ++dataColumn;
      restMinWidth = restMinProdWidth;

      return product;
    });

    setIsSorting(false)
  };

  const renderProducts = () => {
    if (!products.length && searchCurrentValue) {
      return (
        <div className="wrong_container d-flex jc-center">
          <NoResult>
            <Text as="h4" className="no-result__title mt-16">
              No matching illustrations
            </Text>
            <Text as="h5" className="no-result__text mt-4">
              Your search request "{searchCurrentValue}" doesn’t match any
              illustrations. <br />
              Please, change it or check the spelling.
            </Text>
          </NoResult>
        </div>
      );
    }

    return (
      <ul className={galleryClasses.join(' ')} ref={galleryListRef}>
        {map(products, (item, index) => (
            <IllustrationCard
              onSelectTag={onSelectTag}
              key={`product-${item.id}`}
              product={item}
              maxWidth={MAX_GALLERY_WIDTH}
              index={index}
              setIsShowInfo={setIsShowInfo}
              preloader={preloader}
              isSorting={isSorting}
            />
        ))}
      </ul>
    );
  };

  return (
    <div className="gallery__container-page" ref={galleryContainer}>
      {renderProducts()}
    </div>
  );
};

export default ProductsGallery;
