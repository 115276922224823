import React, { useState } from 'react';
import './keywords.scss';

import { map } from 'underscore';
import { Text, Button, Input } from '@ui-kit';
import ArrowIcon from '@ui-kit/icons/chevron-gray500.svg';
import DeleteIcon from '@ui-kit/icons/x-gray500.svg';
import Hotkeys from 'react-hot-keys';


const EditKeywords = ({ 
  keywords = [],
  onAddKeywords = () => {},
  onDeleteKeyword = () => {},
  length = 0
}) => {

  let [keywordsInput, setKeywordsInput] = useState('');

  let [viewAll, setViewAll] = useState(false);

  if (!keywords.length) {
    return;
  }

  const collapseTags = (event) => {
    setTimeout(()=> setViewAll((value)=> !value), 0);
  };

  const renderTags = () => {
    const copy_keywords = [...keywords];

    if (!viewAll) {
      const cuttedKeywordw = copy_keywords.slice(0, length);

      return map(cuttedKeywordw, (keyword, index) =>
        checkLength(keyword, index)
      );
    }

    return map(copy_keywords, (keyword, index) => checkLength(keyword, index));
  };

  const checkLength = (keyword, index) => {
    if (!keyword.length) {
      return;
    }

    return (
      <div className="keywords__button mr-8 mb-8">
        <Button
          key={`keyword-${index}`}
          className="keywords__item"
          theme="secondary-gray"
          size="sm"
          onClick={(event) => onDeleteKeyword(keyword, event)}
        >
          {keyword}
        <Button 
          theme="link-gray"
          id={keyword}
          size="lg"
          iconRight={DeleteIcon}
          className="delete-keyword"
          onClick={onDelete}
        />
        </Button>
        
      </div>
    );
  };

  const renderButton = () => {
    if (checkAmount()) {
      return;
    }

    const classes = ['tag__parent', 'mr-8', 'mb-8'];
    const icon = ArrowIcon;
    let label = 'View all';

    if (viewAll) {
      classes.push('right');
      label = 'Show less';
    }

    return (
      <Button
        key="view-all"
        className={classes.join(' ')}
        theme="secondary-gray keywords__item"
        size="sm"
        iconRight={icon}
        iconLeft={icon}
        onClick={collapseTags}
      >
        {label}
      </Button>
    );
  };

  const checkAmount = () => {
    return keywords.length <= length;
  };

  const onDelete = (event) => {
    const value = event.target.id
    console.log('delete key', value)
  }

  const onKeywordsInputSubmit = () => {

    onAddKeywords( keywordsInput );

    setKeywordsInput('');

  };

  return (
    <div className="keywords__container">
      <div className="keywords__header mb-12">
      <Text as="h4" className="keywords__title mb-12">
        Tags
      </Text>
      <Hotkeys 
         keyName="enter"
         filter={() => true}
         onKeyDown={onKeywordsInputSubmit}
      >
        <Input 
          name="Keywords"
          placeholder="Add keywords, separated by a comma"
          className="keywords__tags"
          value={keywordsInput}
          onChange={evt => setKeywordsInput(evt.target.value)}
        />
      </Hotkeys>
      </div>
      <div className="keywords__list d-flex">
        {renderTags()}
        {renderButton()}
      </div>
    </div>
  );
};

export default EditKeywords;
